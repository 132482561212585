export function isDescendant(parent: string, child?: EventTarget | null) {
  if (!child) return false;

  // @ts-ignore
  var node: HTMLElement = child.parentNode;
  while (node != null && node.tagName !== "document") {
    if (node.className?.split(' ').includes(parent)) {
      return true;
    }
    // @ts-ignore
    node = node.parentNode;
  }
  return false;
}